// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white: #ffffff;
$gray-100: #eeeeee;
$gray-200: #dddddd;
$gray-300: #bbbbbb;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #999999;
$gray-700: #777777;
$gray-800: #555555;
$gray-900: #222222;
$black: #000000;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
);
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue: #4726e1;
$indigo: #6610f2;
$purple: #300d81;
$pink: #d63384;
$red: #d34f2d;
$orange: #f08d34;
$yellow: #f7cc76;
$green: #1d4b40;
$teal: #20c997;
$cyan: #0dcaf0;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
  "black": $black,
  "border-color": $gray-200,
);
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: #0C7A71;
$secondary: $gray-800;
$success: #198754;
$info: #3A5683;
$warning: #FCBA04;
$danger: #E55381;
$light: $gray-100;
$dark: $gray-900;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);
// scss-docs-end theme-colors-map

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded: false;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.5,
  2: $spacer,
  3: $spacer * 1.5,
  4: $spacer * 2,
  5: $spacer * 2.5,
  6: $spacer * 3,
  7: $spacer * 3.5,
  8: $spacer * 4,
  9: $spacer * 4.5,
  10: $spacer * 5,
  15: $spacer * 7.5,
  20: $spacer * 10,
);

// Position
//
// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
$position-values: (
  0: 0,
  10: 10%,
  20: 20%,
  25: 25%,
  30: 30%,
  40: 40%,
  50: 50%,
  60: 60%,
  70: 70%,
  75: 75%,
  80: 80%,
  90: 90%,
  100: 100%,
);
// scss-docs-end position-map

// Body
//
// Settings for the `<body>` element.

$body-color: $black;

// Links
//
// Style anchor elements.

$link-decoration: none;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width: 40px;

// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-color: $gray-200;
// scss-docs-end border-variables

$component-active-color: $white;
$component-active-bg: $primary;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif: "Inter", sans-serif;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.125;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$line-height-base: 1.5;

$h1-font-size: $font-size-base * 3.5; // 56px
$h2-font-size: $font-size-base * 3; // 48px
$h3-font-size: $font-size-base * 2.5; // 40px
$h4-font-size: $font-size-base * 2; // 32px
$h5-font-size: $font-size-base * 1.5; // 24px
$h6-font-size: $font-size-base * 1; // 20px
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  sm: $font-size-sm,
  lg: $font-size-lg,
  base: $font-size-base,
);
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom: 0.5em;
$headings-font-weight: 500;
$headings-line-height: 1.25;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-font-sizes: (
  1: $h1-font-size * 1.2,
  2: $h2-font-size * 1.2,
  3: $h3-font-size * 1.2,
  4: $h4-font-size * 1.2,
  5: $h5-font-size * 1.2,
  6: $h6-font-size * 1.2,
);

$display-font-weight: 500;
$display-line-height: $headings-line-height;
// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 400;

$text-muted: $gray-700;
// scss-docs-end type-variables

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: 1rem;
$input-btn-line-height: $line-height-base;
$input-btn-padding-y-sm: 0.75rem;
$input-btn-padding-y-lg: 1.25rem;
// scss-docs-end input-btn-variables

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y: 1rem; // make default
$btn-padding-x: 2rem;
$btn-padding-y-sm: 0.75rem; // make default
$btn-padding-x-sm: 1.5rem;

$btn-padding-y-lg: 1.25rem; // make default
$btn-padding-x-lg: 2.5rem;

$btn-focus-width: 0;

// Forms

// scss-docs-start form-label-variables
$form-label-margin-bottom: 0.25rem;
$form-label-font-size: 1rem;
$form-label-font-weight:                500;
$form-label-color: $gray-800;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y: 1rem;
// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
$form-check-input-width: 1.5em;
$form-check-transition: 0.15s ease-in-out;

$form-check-input-checked-color: $component-active-color;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='miter' stroke-linejoin='miter' stroke-width='2' d='M6 10l3 3l6-6'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-5 -5 10 10'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");
// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
$form-switch-color: rgba(0, 0, 0, 0.25);
$form-switch-width: 3em;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");
$form-switch-checked-color: $component-active-color;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>");
// scss-docs-end form-switch-variables

// scss-docs-start form-select-variables
$form-select-focus-box-shadow: none;
// scss-docs-end form-select-variables

// Navs

// scss-docs-start nav-variables
$nav-link-color:                    $black;
// scss-docs-end nav-variables

// Navbar

// scss-docs-start navbar-variables
$navbar-brand-margin-end: 4rem;
// scss-docs-end navbar-variables

// scss-docs-start navbar-theme-variables
$navbar-light-active-color:         rgba($primary, .9);
// scss-docs-end navbar-theme-variables

// Pagination

// scss-docs-start pagination-variables
$pagination-bg: transparent;
$pagination-border-width: 0;
// scss-docs-end pagination-variables

// Cards

// scss-docs-start card-variables
$card-spacer-y: 2.5rem;
$card-spacer-x: 2.5rem;
$card-border-width: 0;
$card-cap-padding-y: $card-spacer-y;
$card-cap-bg: transparent;
$card-bg: transparent;
// scss-docs-end card-variables

// Badges

// scss-docs-start badge-variables
$badge-font-size: $font-size-sm;
$badge-font-weight: $font-weight-normal;
$badge-padding-y: 0.5em;
$badge-padding-x: 0.75em;
// scss-docs-end badge-variables

// Modals

// scss-docs-start modal-variables
$modal-inner-padding: $card-spacer-y;
$modal-content-border-width: 0;
$modal-backdrop-bg:                 $black;
$modal-backdrop-opacity:            .85;
// scss-docs-end modal-variables

// Alerts
//
// Define alert colors, border radius, and padding.

// scss-docs-start alert-variables
$alert-padding-y: $card-spacer-y;
$alert-padding-x: $card-spacer-x;
$alert-border-width: 0;
$alert-border-scale: 0%;
// scss-docs-end alert-variables

// List group

// scss-docs-start list-group-variables
$list-group-color: inherit;
$list-group-bg: transparent;
// scss-docs-end list-group-variables

// Offcanvas

// scss-docs-start offcanvas-variables
$offcanvas-transition-duration: 0.4s;
// scss-docs-end offcanvas-variables

// Code

// default: 10, needs to be > 1
$rfs-factor: 200;

// Figures

// scss-docs-start figure-variables
$figure-caption-color: $white;
// scss-docs-end figure-variables

