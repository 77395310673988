//
// your custom scss goes here
//
/*
@import url('https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vujahday+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Outlined');

.fs-handwritten {
	font-family: 'Nothing You Could Do', cursive;
	letter-spacing: -0.02em;
}
*/
.plyr {
	--plyr-color-main: var(--bs-primary);
	--plyr-font-family: 'monospace';
}

// pikaday
$pd-day-hover-bg: $secondary;
$pd-day-today-color: $primary;
$pd-day-selected-color: $white;
$pd-day-selected-bg: $primary;
$pd-day-selected-shadow: $light;
@import "~pikaday/scss/pikaday";

.order-steps {
	.number {
		display: flex;
		width: 3.5rem;
		height: 3.5rem;
		align-items: center;
		justify-content: center;
		font-size: 1.125rem;
		background: var(--bs-gray-300);
		color: #ffffff;
		border-radius: 50%;
	}
	.number.active {
		background-color: var(--bs-primary);
	}
	.line {
		height: 0.125rem;
		width: calc(100% - 4.5rem);
		display: flex;
		background: #dddddd;
	}
	.caption {
		margin-top: 1rem;
		font-size: 1rem;
		color: var(--bs-gray-300);
	}
	.caption.active {
		display: block !important;
		color: var(--bs-gray-900);
	}
}

/* For user generated content, it's easier to add the white-space pre to
 * maintain spacing */
.order-overview p{
    white-space:pre-wrap;
}

/* For star select */
.star-select:hover {
   color:$warning;
}

$aspect-ratios: map-merge(
  $aspect-ratios,
  (
    "3x2": calc(2 / 3 * 100%)
  ),
);

